import { createApp } from 'vue'
import App from './App.vue'
import router from './router'
import api from './services/WebService'
import Notifications from '@kyvg/vue3-notification'
import helperService from './services/HelperService';
import sharedService from './services/ShareService';
import vue3StarRatings from "vue3-star-ratings";
import Dropdown from 'vue-simple-search-dropdown';
import PrimeVue from 'primevue/config';
import "./services/ValidationRules";
//import '../public/sass/main.scss';
import store from './services/StoreService';

import {
    createI18n
} from "vue-i18n";
import messages from "./messages.js";
const i18n = createI18n({
    locale: localStorage.getItem('language') ? localStorage.getItem('language').toLowerCase() : "ar",
    fallbackLocale: localStorage.getItem('language') ? localStorage.getItem('language').toLowerCase() : "ar",
    messages,
});
const app = createApp(App);
app.use(i18n);
app.component("vue3-star-ratings", vue3StarRatings);
app.use(router)
app.use(Notifications)
app.mount('#app')
app.use(store);
app.config.globalProperties.$helperService = helperService;
app.config.globalProperties.$api = api;
app.config.globalProperties.$sharedService = sharedService;
app.use(Dropdown);
app.use(PrimeVue);