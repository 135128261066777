<template >
  <main role="main">
    <div id="main-wrapper" class="login-wrap">
      <div class="container-fluid">
        <div class="row justify-content-md-center align-items-center min-vh-100">
          <div class="col-lg-6 col-md-9">
              <div class="row justify-content-center">
              <div class="col-lg-9">
                <div class="card px-lg-5 px-0 py-3">
                  <div class="card-body pb-0 text-center">
                    <img
                      src="images/gardening_new_log.jpg"
                      width="180"
                      height="37"
                      class="img-fluid mb-4 mx-auto"
                    />
                  </div>
                  <div class="card-body pt-1 pb-2">
                    <form class="form-style floating-form">
                      <Form @submit="forgotPassword" class="form-style">
                        <p class="font-20 font-medium">Forgot your password?</p>
                        <p class="text-secondary">
                          Enter your registered email addess to get a link to
                          reset your password
                        </p>

                        <div class="form-group">
                          <label for="email">Email</label
                          ><Field
                            type="email"
                            id="email"
                            name="email"
                            class="form-control"
                            rules="required|email"
                            v-model="details.email"
                            :validateOnInput="true"
                          /><!---->
                        </div>
                        <ErrorMessage name="email" class="text-danger" />
                        <button
                          id="submit-button"
                          style="cursor: pointer"
                          class="btn btn-brand-1 btn-block"
                        >
                          Submit
                        </button>
                      </Form>
                    </form>
                  </div>
                  <div class="card-footer text-center bg-white border-0">
                    <div class="links">
                      <i class="fa fa-angle-arrow-left" aria-hidden="true"></i>
                      Back to
                      <a href="/login"  style="color:#00BB59 !important;" class="ml-2 text-primary font-medium">
                        Login</a
                      >
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <!-- <div class="col-md-6 login-img-col" style="min-height: 92vh"></div> -->
        </div>
      </div>
    </div>
  </main>

  <!--succes modal-->
  <TextModal ref="textModal">
    <div class="modal-content">
      <div class="modal-header">
        <h5 class="modal-title font-22">Successful!</h5>
        <button
          type="button"
          class="close"
          data-dismiss="modal"
          aria-label="Close"
        >
          <span aria-hidden="true">&times;</span>
        </button>
      </div>
      <div class="modal-body text-center px-md-5">
        <img src="images/claping.png" alt="" width="90" class="mb-3" />
        <p class="font-18 mb-0">
          We have sent a reset password link to your email address
        </p>
      </div>
      <div
        class="
          modal-footer
          justify-content-center
          border-0
          pb-4
          pt-0
          px-md-5
          text-center
        "
      >
        <button
          type="button"
          class="btn btn-outline-brand-1 w-100 mb-2"
          onclick="location.href='reset-password.php'"
        >
          OK
        </button>
        <p>
          Didn't received yet?
          <a href="javascript:;void(0)" data-dismiss="modal" style="color:#00BB59 !important;">Resend</a>.
        </p>
      </div>
    </div>
  </TextModal>

  <!-- <main role="main">
    <div id="main-wrapper" class="login-wrap">
      <div class="container-fluid h-100">
        <div
          class="
            row
            justify-content-md-center
            align-items-center
            h-100
            bg-white
          "
        >
          <div class="col-md-6 bg-white">
            <div class="row justify-content-center">
              <div class="col-lg-10">
                <div class="px-lg-5 px-0 py-3">
                  <div
                    class="card-header bg-white rounded-10 text-center border-0"
                  >
                    <img
                      src="/images/beauty-logo.svg"
                      class="img-fluid mb-lg-5 mb-4"
                      width="200"
                      height="96"
                    />
                  </div>
                  <div class="card-body pt-1">
                    <div class="font-medium mb-3">Forgot your password?</div>
                    <div class="mb-3 font-14">
                      Enter your registered email address to get a link to reset
                      your password
                    </div>
                    <Form @submit="forgotPassword" class="form-style">
                      <div class="form-group mb-4">
                        <label for="email">Email</label
                        ><Field
                          type="email"
                          id="email"
                          name="email"
                          class="form-control"
                          rules="required|email"
                          v-model="details.email"
                          :validateOnInput="true"
                        />-->
  <!-- </div>
                      <ErrorMessage name="email" class="text-danger" />
                      <button
                        class="btn btn-black w-100 mb-4"
                        id="submit-button"
                        style="cursor: pointer"
                      >
                        Submit
                      </button>
                      <div class="card-footer text-center bg-white border-0">
                        <div class="links font-14">
                          Back to login
                          <a
                            href="/login"
                            class="ml-2 text-brandy text-decoration-none"
                            >Login</a
                          >
                        </div>
                      </div>
                    </Form>
                    <TextModal ref="textModal">
                      <div class="modal-header">
                        <div class="row col-12">
                          <h5 class="col-11" style="padding-left: 72px">
                            Successful!
                          </h5>
                          <a
                            type="button"
                            class="close col-1"
                            v-on:click="$refs.textModal.closeModal()"
                          >
                            <img
                              src="/images/cross.png"
                              class="img-fluid mb-lg-5 mb-4"
                            />
                          </a>
                        </div>
                        <div class="bg-white rounded-10 text-center border-0">
                          <img
                            src="/images/success.svg"
                            class="img-fluid mb-lg-5 mb-4"
                            width="85"
                            heaight="20"
                          />
                          <div class="">
                            We have sent a reset password link <br />to your
                            email address
                          </div>
                          <button
                            type="button"
                            class="btn btn-outline-success text-center"
                            style="width: 200px; height: 40px"
                            v-on:click="$router.push('/login')"
                          >
                            OK</button
                          ><br />
                          Didn't received yet?
                          <a style="cursor: pointer" v-on:click="resendOtp()"
                            >Resend.</a
                          >
                        </div>
                      </div>
                    </TextModal>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div class="col-md-6 login-img-col" style="min-height: 92vh"></div>
        </div>
      </div>
    </div>
  </main> -->
</template>
<script>
import { Form, Field, ErrorMessage } from "vee-validate";
import TextModal from "@/components/TextModal";
import api from "../../services/WebService";
// import router from "../../router";
import $ from "jquery";
export default {
  name: "ForgotPassword",
  components: {
    Form,
    Field,
    ErrorMessage,
    TextModal,
  },
  data() {
    return {
      details: {},
    };
  },
  mounted() {
    //floating form
    $(document).ready(function () {
      $(
        ".floating-form-group input,.floating-form-group textarea,.floating-form select"
      ).change(function () {
        if ($(this).val() != "") {
          $(this).addClass("filled");
        } else {
          $(this).removeClass("filled");
        }
      });
    });
  },
  methods: {
    showPopup() {
      this.$refs.textModal.showModal();
    },
    forgotPassword() {
      api
        .postAPI({
          _action: "forgot-password",
          _body: this.details,
          _buttonId: "submit-button",
        })
        .then((res) => {
          if (res) {
            localStorage.setItem("email", this.details.email);
            this.showPopup();
            this.$notify({
              type: "success",
              text: res.message,
            });
          }
        })
        .catch(() => {});
    },
    resendOtp() {
      api
        .postAPI({
          _action: "resend-otp",
          _body: this.details,
        })
        .then((res) => {
          if (res) {
            this.showPopup();

            this.$notify({
              type: "success",
              text: res.message,
            });
          }
        })
        .catch(() => {});
    },
  },
};
</script>