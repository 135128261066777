<template>
  <div class="">
    <div class="page-content">
      <div class="row mb-4 justify-content-center">
        <div class="col-lg-12">
          <div class="row align-items-center">
            <!-- <div class="col-md-6 text-md-left text-center">
              <h2 class="page-title mb-0">Gardening</h2>
            </div> -->
            <div class="card-body pt-0">
              <h4 class="font-20 pb-2">{{ detail.title }}</h4>

               <div v-html="detail.description"></div>
            </div>
            <!-- <div class="col-md-6 text-right d-flex justify-content-end"></div> -->
          </div>
          <!-- <div class="card mb-4 rounded-0 border-0 bg-transparent"> -->
          <!-- <div class="card-header p-0 border-0">
              <img
                src="/images/gardening_new_log.jpg"
                class="card-img-top rounded-common"
                alt=""
              />
            </div> -->
        </div>
      </div>
    </div>
  </div>
  <!-- </div> -->
</template>

<script>
export default {
  name: "TermsAndConditions",
  components: {},
  data() {
    return {
      type: this.$route.params.type,
      detail: {},
    };
  },
  mounted() {
    this.getDetail();
  },
  methods: {
    getDetail() {
      this.$api
        .getAPI({
          _action: "static-content?type=" + this.type,
        })
        .then((res) => {
          for (var i in res.info) {
            console.log("data",res.info[i]);
            this.detail=res.info[i];
          }
          console.log("detail",this.detail);
        })
        .catch(() => {});
    },
  },
};
</script>