<template >
  <div>
    <main role="main">
      <div id="main-wrapper" class="login-wrap">
        <div class="container-fluid">
          <div
            class="row justify-content-md-center align-items-center min-vh-100"
          >
            <div class="col-lg-6 col-md-9">
              <div class="row justify-content-center">
                <div class="col-lg-9">
                  <div class="card px-lg-5 px-0 py-3">
                    <div class="card-body">
                      <div class="text-center">
                        <img
                          src="images/gardening_new_log.jpg"
                          width="180"
                          height="37"
                          class="img-fluid mb-5 mx-auto"
                        />
                        <p class="font-medium font-18">OTP Confirmation</p>
                        <p class="text-secondary font-14">
                          Please enter the verification code sent to your email.
                        </p>
                      </div>

                      <div
                        class="form-group otp-element mb-0 col-md-10 mx-auto"
                      >
                        <form class="d-flex justify-content-between">
                          <input
                            type="text"
                            class="otp-control"
                            id="codeBox1"
                            name="otp"
                            maxlength="1"
                            v-on:keyup="onKeyUpEvent(1, $event)"
                            v-on:focus="onFocusEvent(1)"
                          />
                          <input
                            id="codeBox2"
                            type="text"
                            class="otp-control"
                            maxlength="1"
                            name="otp"
                            v-on:keyup="onKeyUpEvent(2, $event)"
                            v-on:focus="onFocusEvent(2)"
                          />
                          <input
                            id="codeBox3"
                            type="text"
                            class="otp-control"
                            maxlength="1"
                            name="otp"
                            v-on:keyup="onKeyUpEvent(3, $event)"
                            v-on:focus="onFocusEvent(3)"
                          />
                          <input
                            id="codeBox4"
                            type="text"
                            class="otp-control"
                            maxlength="1"
                            name="otp"
                            v-on:keyup="onKeyUpEvent(4, $event)"
                            v-on:focus="onFocusEvent(4)"
                          />
                        </form>
                      </div>
                      <p style="color: red" v-show="this.validate">
                        OTP is required
                      </p>
                    </div>
                    <div
                      class="card-footer text-center bg-white border-0 px-md-5"
                    >
                      <button
                        type="button"
                        class="btn btn-brand-1 btn-block"
                        v-on:click="verifyOtp()"
                        id="otp-button"
                      >
                        Submit
                      </button>
                      <div class="mt-3">
                        <p
                          id="otp_timer"
                          class="font-20 mb-2"
                          style="color: #666666"
                        ></p>
                        <p>
                          <a
                            class="
                              text-color-2
                              font-16
                              text-primary
                              cursor-pointer
                            "
                            v-on:click="resendOtp()"
                            >Resend OTP</a
                          >
                        </p>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </main>
    <!-- <main role="main">
      <div id="main-wrapper" class="login-wrap">
        <div class="container-fluid h-100">
          <div
            class="
              row
              justify-content-md-center
              align-items-center
              h-100
              bg-white
            "
          >
            <div class="col-md-6 bg-white">
              <div class="row justify-content-center">
                <div class="col-md-7 col-12">
                  <div class="px-lg-5 px-0 py-3">
                    <div
                      class="
                        card-header
                        bg-white
                        rounded-10
                        text-center
                        border-0
                      "
                    >
                      <img
                        src="/images/beauty-logo.svg"
                        width="200"
                        height="96"
                        class="img-fluid mb-3"
                      />
                    </div>
                    <div class="card-body">
                      <div class="text-center">
                        <p class="font-medium font-18">OTP Confirmation</p>
                        <p class="text-secondary font-14">
                          Please enter the verification code sent to your email.
                        </p>
                      </div>

                      <div
                        class="form-group otp-element mb-0 col-md-10 mx-auto"
                      >
                        <form class="d-flex justify-content-between">
                          <input
                            type="text"
                            class="otp-control"
                            id="codeBox1"
                            name="otp"
                            maxlength="1"
                            v-on:keyup="onKeyUpEvent(1, $event)"
                            v-on:focus="onFocusEvent(1)"
                          />
                          <input
                            id="codeBox2"
                            type="text"
                            class="otp-control"
                            maxlength="1"
                            name="otp"
                            v-on:keyup="onKeyUpEvent(2, $event)"
                            v-on:focus="onFocusEvent(2)"
                          />
                          <input
                            id="codeBox3"
                            type="text"
                            class="otp-control"
                            maxlength="1"
                            name="otp"
                            v-on:keyup="onKeyUpEvent(3, $event)"
                            v-on:focus="onFocusEvent(3)"
                          />
                          <input
                            id="codeBox4"
                            type="text"
                            class="otp-control"
                            maxlength="1"
                            name="otp"
                            v-on:keyup="onKeyUpEvent(4, $event)"
                            v-on:focus="onFocusEvent(4)"
                          />
                        </form>
                      </div>
                      <p style="color: red" v-show="this.validate">
                        OTP is required
                      </p>
                    </div>
                    <div
                      class="card-footer text-center bg-white border-0 px-md-5"
                    >
                      <button
                        type="button"
                        class="btn btn-black w-100"
                        v-on:click="verifyOtp()"
                        id="otp-button"
                      >
                        Submit
                      </button>
                      <div class="mt-3">
                        <p
                          id="otp_timer"
                          class="font-20 mb-2"
                          style="color: #666666"
                        ></p>
                        <p>
                          <a
                            v-on:click="resendOtp()"
                            class="
                              text-color-2
                              font-16
                              text-primary
                              cursor-pointer
                            "
                            >Resend OTP</a
                          >
                        </p>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div class="col-md-6 login-img-col"></div>
          </div>
        </div>
      </div>
    </main> -->
  </div>
</template>
<script>
export default {
  name: "otp-screen",
  data() {
    return {
      validate: false,
      details: {},
    };
  },
  mounted() {
    // var $this = this;
    // setTimeout(function () {
    //   $this.initApple();
    // }, 1000);
    this.MeCall();
  },
  methods: {
    // OTP
    getCodeBoxElement(index) {
      return document.getElementById("codeBox" + index);
    },
    onKeyUpEvent(index, event) {
      const eventCode = event.which || event.keyCode;
      if (this.getCodeBoxElement(index).value.length == 1) {
        if (index !== 4) {
          this.getCodeBoxElement(index + 1).focus();
        } else {
          this.getCodeBoxElement(index).blur();
          // Submit code
          console.log("submit code ");
          this.validate = false;
        }
      }
      if (eventCode === 8 && index !== 1) {
        this.getCodeBoxElement(index - 1).focus();
        this.validate = true;
      } else {
        this.validate = false;
      }
    },
    onFocusEvent(index) {
      for (var item = 1; item < index; item++) {
        const currentElement = this.getCodeBoxElement(item);
        if (!currentElement.value) {
          currentElement.focus();
          break;
        }
      }
    },
    MeCall() {
      this.$api
        .getAPI({
          _action: "me",
        })
        .then((res) => {
          if (res) {
            this.details = res.info;
          } else {
            localStorage.removeItem("access_token");
            return;
          }
        })
        .catch(() => {
          localStorage.removeItem("access_token");
        });
    },

    verifyOtp() {
      var obj = {};
      // obj.dial_code = this.details.dial_code;
      obj.otp =
        document.getElementById("codeBox1").value +
        "" +
        document.getElementById("codeBox2").value +
        "" +
        document.getElementById("codeBox3").value +
        "" +
        document.getElementById("codeBox4").value;
      // console.log("otp", obj.otp);
      if (obj.otp.length == 4) {
        this.$api
          .postAPI({
            _action: "otp/verify",
            _body: obj,
          })
          .then((res) => {
            if (res) {
              this.$notify({
                type: "success",
                text: res.message,
              });
              this.$router.push("/home");
            } else {
              console.log("error");
            }
          })
          .catch((e) => {
            console.log("error", e);
          });
      } else {
        this.validate = true;
      }
    },
    resendOtp() {
      var obj = {
        dial_code: this.details.dial_code,
        phone_number: this.details.phone_number,
        country_code: this.details.country_code
      };
      this.$api
        .postAPI({
          _action: "otp/resend",
          _body: obj,
        })
        .then((res) => {
          if (res) {
            this.$notify({
              type: "success",
              text: res.message,
            });
          }
        })
        .catch((e) => {
          console.log("error", e);
        });
    },
  },
};
</script>
