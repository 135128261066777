<template >
  <div>
    <!-- <OuterHeader/> -->
    <main role="main">
      <div id="main-wrapper" class="login-wrap">
        <div class="container-fluid">
          <div class="row justify-content-md-center align-items-center min-vh-100">
            <div class="col-lg-6 col-md-9">
              <div class="row justify-content-center">
                <div class="col-lg-9">
                  <div class="card px-lg-5 px-0 py-3">
                    <div class="card-body pb-0 text-center">
                      <img
                      src="images/gardening_new_log.jpg"
                      width="180"
                      height="37"
                      class="img-fluid mb-4 mx-auto"
                    />
                    </div>

                    <div class="card-body pt-1 pb-2">
                      <Form @submit="save" class="form-style floating-form">
                        <div class="floating-form-group mb-4">
                          <label for="email">New Password</label>
                          <Field
                            :type="newPasswordFieldType"
                            id="password"
                            name="password"
                            placeholder="New Password"
                            v-on:keydown.space="$event.preventDefault()"
                            rules="required:password|minLength:6|password"
                            :validateOnInput="true"
                          />
                          <ErrorMessage
                            name="password"
                            class="validation-msg"
                          />
                          <a class="psw-icon" v-on:click="switchVisibilityNew"
                            ><img :src="imageNew" width="20"
                          /></a>
                        </div>
                        <div class="floating-form-group mb-4">
                          <label>Confirm Password</label>
                          <Field
                            :type="passwordFieldType"
                            id="conf_password"
                            placeholder="Confirm Password"
                            name="confirm_password"
                            v-on:keydown.space="$event.preventDefault()"
                            rules="required:confirm password|confirmed:@password"
                            :validateOnInput="true"
                          />
                          <ErrorMessage
                            name="confirm_password"
                            class="validation-msg"
                          />
                          <a class="psw-icon" v-on:click="switchVisibility"
                            ><img :src="image" width="20"
                          /></a>
                        </div>
                        <button
                          type="button"
                          class="btn btn-brand-1 btn-block mb-4"
                          id="submit-button"
                          v-on:click="$router.push('/login')"
                          style="cursor: pointer"
                        >
                          Submit
                        </button>
                      </Form>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <!-- <div class="col-md-6 login-img-col" style="min-height: 92vh"></div> -->
          </div>
        </div>
      </div>
    </main>
  </div>
</template>
<script>
// import OuterHeader from "@/components/OuterHeader";
import { Form, Field, ErrorMessage } from "vee-validate";
export default {
  name: "ResetPassword",
  components: {
    Form,
    Field,
    ErrorMessage,
    // OuterHeader
  },
  data() {
    return {
      newPasswordFieldType: "password",
      passwordFieldType: "password",
      image: "/images/show.png",
      imageNew: "/images/show.png",
    };
  },
  mounted() {},
  methods: {
    switchVisibility() {
      this.passwordFieldType =
        this.passwordFieldType === "password" ? "text" : "password";
      this.image =
        this.image === "/images/show.png"
          ? "/images/hide.png"
          : "/images/show.png";
    },
    switchVisibilityNew() {
      this.newPasswordFieldType =
        this.newPasswordFieldType === "password" ? "text" : "password";
      this.imageNew =
        this.imageNew === "/images/show.png"
          ? "/images/hide.png"
          : "/images/show.png";
    },
  },
};
</script>
