<template>
  <div>
    <main role="main">
      <div id="main-wrapper" class="login-wrap">
        <div class="container-fluid">
          <div class="row justify-content-md-center align-items-center min-vh-100">
            <div class="col-lg-6 col-md-9">
              <div class="row justify-content-center align-items-center">
                <div class="col-lg-9 py-4">
                  <div class="px-lg-5 px-0 py-md-4 card">
                    <div class="card-body pb-0">
                      <div class="text-center">
                        <img
                          src="images/gardening_new_log.jpg"
                          width="180"
                          height="37"
                          class="img-fluid mb-4 mx-auto"
                        />
                        <p class="font-medium font-18">Sign In</p>
                      </div>

                      <form class="form-style floating-form">
                        <Form @submit="login">
                          <div class="row">
                            <div class="col-md-12">
                              <div class="form-group">
                                <label for="phone">Phone Number</label>
                                <span class="text-danger">*</span>
                                <Field
                                  type="text"
                                  id="phone"
                                  name="phone"
                                  v-model="details.phone_number"
                                  class="form-control filled"
                                  rules="required:phone number|phone"
                                  :validateOnInput="true"
                                  ><PhoneCode
                                    id="mobile"
                                    placeholder="Mobile Number"
                                    v-model:countryCode="details.country_code"
                                    v-model="details.phone_number"
                                    v-model:dialCode="details.dial_code"
                                    v-bind="field"
                                  />
                                </Field>
                              </div>
                              <ErrorMessage name="phone" class="validation-msg" />
                            </div>

                            <div class="col-md-12">
                              <div class="floating-form-group mb-3 with-icon">
                                <Field
                                  :type="passwordFieldType"
                                  id="password"
                                  v-model="details.password"
                                  name="Password"
                                  rules="required:password"
                                  :validateOnInput="true"
                                />
                                <label for="password">Password</label>
                                <i class="bx bx-lock icon"></i>
                                <span
                                  class="input-group-text bg-white"
                                  id="basic-addon2"
                                  style="border-radius: 0px 8px 8px 0px"
                                  ><a v-on:click="switchVisibility" class="psw-icon"
                                    ><img :src="image" width="20" /></a
                                ></span>
                                <ErrorMessage name="Password" class="validation-msg" />
                              </div>
                            </div>
                          </div>
                          <div class="form-check pl-0 mb-3 overflow-auto">
                            <a
                              class="text-dark float-right"
                              style="color: #00bb59 !important"
                              href="/forgot-password"
                              >Forgot Password?</a
                            >
                          </div>
                          <button
                            id="login-button"
                            style="cursor: pointer"
                            class="btn btn-brand-1 btn-block"
                          >
                            Sign in
                          </button>
                        </Form>
                      </form>
                    </div>
                    <div class="card-footer text-center bg-white border-0">
                      <div class="links">
                        Don't have an account?
                        <a
                          href="signup"
                          class="ml-2 text-primary font-medium"
                          style="color: #00bb59 !important"
                        >
                          Sign Up</a
                        >
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </main>
  </div>
</template>
<script>
import { Form, Field, ErrorMessage } from "vee-validate";
import PhoneCode from "../../components/PhoneCodePicker/PhoneCode.vue";
import $ from "jquery";
export default {
  name: "LoginPage",
  components: {
    Form,
    Field,
    ErrorMessage,
    PhoneCode,
  },
  data() {
    return {
      passwordFieldType: "password",
      image: "/images/show.png",
      details: {
        email: "",
        password: "",
        dial_code: "+966",
        country_code: "sa",
      },
    };
  },
  mounted() {
    localStorage.clear();
    $(document).ready(function () {
      $(
        ".floating-form-group input,.floating-form-group textarea,.floating-form select"
      ).change(function () {
        if ($(this).val() != "") {
          $(this).addClass("filled");
        } else {
          $(this).removeClass("filled");
        }
      });
    });
  },
  methods: {
    login() {
      this.$api
        .postAPI({
          _action: "login",
          _body: this.details,
          _buttonId: "login-button",
        })
        .then((res) => {
          if (res && res.access_token) {
            localStorage.setItem("access_token", res.access_token);
            this.getMe();
          }
        })
        .catch(() => {});
    },
    getMe() {
      this.$api
        .getAPI({
          _action: "me",
        })
        .then((res) => {
          if (res) {
            this.$store.commit("setUser", res.info);
          } else {
            localStorage.removeItem("access_token");
            return;
          }
          localStorage.setItem("userId", res.info.id);
          localStorage.setItem("supplier_id", res.info.company.supplier_id);
          this.$router.push("/home");
        })
        .catch(() => {
          localStorage.removeItem("access_token");
        });
    },
    switchVisibility() {
      this.passwordFieldType =
        this.passwordFieldType === "password" ? "text" : "password";
      this.image =
        this.image === "/images/show.png" ? "/images/hide.png" : "/images/show.png";
    },
  },
};
</script>

